
          export const batfishContext = {
            selectedConfig: {
              siteBasePath: '',
              siteOrigin: 'https://track-asia-vn.github.io',
              hijackLinks: true,
              manageScrollRestoration: true
            },
            routes: [{
            path: '/',
            getPage: () => import(
              /* webpackChunkName: "home" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_.js'
            ),
            
            
          },{
            path: '/404/',
            getPage: () => import(
              /* webpackChunkName: "not-found" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_404.js'
            ),
            
            is404: true,
          },{
            path: '/api/',
            getPage: () => import(
              /* webpackChunkName: "api" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_api.js'
            ),
            
            
          },{
            path: '/api/events/',
            getPage: () => import(
              /* webpackChunkName: "api-events" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_api_events.js'
            ),
            
            
          },{
            path: '/api/geography/',
            getPage: () => import(
              /* webpackChunkName: "api-geography" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_api_geography.js'
            ),
            
            
          },{
            path: '/api/handlers/',
            getPage: () => import(
              /* webpackChunkName: "api-handlers" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_api_handlers.js'
            ),
            
            
          },{
            path: '/api/map/',
            getPage: () => import(
              /* webpackChunkName: "api-map" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_api_map.js'
            ),
            
            
          },{
            path: '/api/markers/',
            getPage: () => import(
              /* webpackChunkName: "api-markers" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_api_markers.js'
            ),
            
            
          },{
            path: '/api/properties/',
            getPage: () => import(
              /* webpackChunkName: "api-properties" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_api_properties.js'
            ),
            
            
          },{
            path: '/api/sources/',
            getPage: () => import(
              /* webpackChunkName: "api-sources" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_api_sources.js'
            ),
            
            
          },{
            path: '/example/',
            getPage: () => import(
              /* webpackChunkName: "example" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example.js'
            ),
            
            
          },{
            path: '/example/3d-buildings/',
            getPage: () => import(
              /* webpackChunkName: "example-3d-buildings" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_3d-buildings.js'
            ),
            
            
          },{
            path: '/example/3d-extrusion-floorplan/',
            getPage: () => import(
              /* webpackChunkName: "example-3d-extrusion-floorplan" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_3d-extrusion-floorplan.js'
            ),
            
            
          },{
            path: '/example/3d-terrain/',
            getPage: () => import(
              /* webpackChunkName: "example-3d-terrain" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_3d-terrain.js'
            ),
            
            
          },{
            path: '/example/add-3d-model-babylon/',
            getPage: () => import(
              /* webpackChunkName: "example-add-3d-model-babylon" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_add-3d-model-babylon.js'
            ),
            
            
          },{
            path: '/example/add-3d-model/',
            getPage: () => import(
              /* webpackChunkName: "example-add-3d-model" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_add-3d-model.js'
            ),
            
            
          },{
            path: '/example/add-a-marker/',
            getPage: () => import(
              /* webpackChunkName: "example-add-a-marker" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_add-a-marker.js'
            ),
            
            
          },{
            path: '/example/add-image-animated/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image-animated" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_add-image-animated.js'
            ),
            
            
          },{
            path: '/example/add-image-generated/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image-generated" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_add-image-generated.js'
            ),
            
            
          },{
            path: '/example/add-image-missing-generated/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image-missing-generated" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_add-image-missing-generated.js'
            ),
            
            
          },{
            path: '/example/add-image-stretchable/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image-stretchable" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_add-image-stretchable.js'
            ),
            
            
          },{
            path: '/example/add-image/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_add-image.js'
            ),
            
            
          },{
            path: '/example/animate-a-line/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-a-line" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_animate-a-line.js'
            ),
            
            
          },{
            path: '/example/animate-camera-around-point/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-camera-around-point" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_animate-camera-around-point.js'
            ),
            
            
          },{
            path: '/example/animate-images/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-images" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_animate-images.js'
            ),
            
            
          },{
            path: '/example/animate-marker/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-marker" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_animate-marker.js'
            ),
            
            
          },{
            path: '/example/animate-point-along-line/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-point-along-line" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_animate-point-along-line.js'
            ),
            
            
          },{
            path: '/example/animate-point-along-route/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-point-along-route" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_animate-point-along-route.js'
            ),
            
            
          },{
            path: '/example/attribution-position/',
            getPage: () => import(
              /* webpackChunkName: "example-attribution-position" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_attribution-position.js'
            ),
            
            
          },{
            path: '/example/camera-animation/',
            getPage: () => import(
              /* webpackChunkName: "example-camera-animation" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_camera-animation.js'
            ),
            
            
          },{
            path: '/example/canvas-source/',
            getPage: () => import(
              /* webpackChunkName: "example-canvas-source" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_canvas-source.js'
            ),
            
            
          },{
            path: '/example/center-on-symbol/',
            getPage: () => import(
              /* webpackChunkName: "example-center-on-symbol" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_center-on-symbol.js'
            ),
            
            
          },{
            path: '/example/change-building-color-based-on-zoom-level/',
            getPage: () => import(
              /* webpackChunkName: "example-change-building-color-based-on-zoom-level" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_change-building-color-based-on-zoom-level.js'
            ),
            
            
          },{
            path: '/example/change-case-of-labels/',
            getPage: () => import(
              /* webpackChunkName: "example-change-case-of-labels" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_change-case-of-labels.js'
            ),
            
            
          },{
            path: '/example/change-map-style/',
            getPage: () => import(
              /* webpackChunkName: "example-change-map-style" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_change-map-style.js'
            ),
            
            
          },{
            path: '/example/check-for-support/',
            getPage: () => import(
              /* webpackChunkName: "example-check-for-support" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_check-for-support.js'
            ),
            
            
          },{
            path: '/example/cluster-html/',
            getPage: () => import(
              /* webpackChunkName: "example-cluster-html" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_cluster-html.js'
            ),
            
            
          },{
            path: '/example/cluster-leaflet/',
            getPage: () => import(
              /* webpackChunkName: "example-cluster-leaflet" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_cluster-leaflet.js'
            ),
            
            
          },{
            path: '/example/cluster/',
            getPage: () => import(
              /* webpackChunkName: "example-cluster" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_cluster.js'
            ),
            
            
          },{
            path: '/example/color-switcher/',
            getPage: () => import(
              /* webpackChunkName: "example-color-switcher" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_color-switcher.js'
            ),
            
            
          },{
            path: '/example/contour-lines/',
            getPage: () => import(
              /* webpackChunkName: "example-contour-lines" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_contour-lines.js'
            ),
            
            
          },{
            path: '/example/custom-marker-icons/',
            getPage: () => import(
              /* webpackChunkName: "example-custom-marker-icons" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_custom-marker-icons.js'
            ),
            
            
          },{
            path: '/example/custom-style-layer/',
            getPage: () => import(
              /* webpackChunkName: "example-custom-style-layer" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_custom-style-layer.js'
            ),
            
            
          },{
            path: '/example/data-driven-lines/',
            getPage: () => import(
              /* webpackChunkName: "example-data-driven-lines" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_data-driven-lines.js'
            ),
            
            
          },{
            path: '/example/direction-between-multiple-custom-points/',
            getPage: () => import(
              /* webpackChunkName: "example-direction-between-multiple-custom-points" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_direction-between-multiple-custom-points.js'
            ),
            
            
          },{
            path: '/example/direction-between-multiple-points/',
            getPage: () => import(
              /* webpackChunkName: "example-direction-between-multiple-points" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_direction-between-multiple-points.js'
            ),
            
            
          },{
            path: '/example/direction-between-two-points/',
            getPage: () => import(
              /* webpackChunkName: "example-direction-between-two-points" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_direction-between-two-points.js'
            ),
            
            
          },{
            path: '/example/disable-rotation/',
            getPage: () => import(
              /* webpackChunkName: "example-disable-rotation" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_disable-rotation.js'
            ),
            
            
          },{
            path: '/example/disable-scroll-zoom/',
            getPage: () => import(
              /* webpackChunkName: "example-disable-scroll-zoom" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_disable-scroll-zoom.js'
            ),
            
            
          },{
            path: '/example/display-and-style-rich-text-labels/',
            getPage: () => import(
              /* webpackChunkName: "example-display-and-style-rich-text-labels" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_display-and-style-rich-text-labels.js'
            ),
            
            
          },{
            path: '/example/display-contextmenu/',
            getPage: () => import(
              /* webpackChunkName: "example-display-contextmenu" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_display-contextmenu.js'
            ),
            
            
          },{
            path: '/example/drag-a-marker/',
            getPage: () => import(
              /* webpackChunkName: "example-drag-a-marker" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_drag-a-marker.js'
            ),
            
            
          },{
            path: '/example/drag-a-point/',
            getPage: () => import(
              /* webpackChunkName: "example-drag-a-point" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_drag-a-point.js'
            ),
            
            
          },{
            path: '/example/fallback-image/',
            getPage: () => import(
              /* webpackChunkName: "example-fallback-image" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_fallback-image.js'
            ),
            
            
          },{
            path: '/example/featuresat/',
            getPage: () => import(
              /* webpackChunkName: "example-featuresat" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_featuresat.js'
            ),
            
            
          },{
            path: '/example/fill-pattern/',
            getPage: () => import(
              /* webpackChunkName: "example-fill-pattern" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_fill-pattern.js'
            ),
            
            
          },{
            path: '/example/filter-markers-by-input/',
            getPage: () => import(
              /* webpackChunkName: "example-filter-markers-by-input" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_filter-markers-by-input.js'
            ),
            
            
          },{
            path: '/example/filter-markers/',
            getPage: () => import(
              /* webpackChunkName: "example-filter-markers" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_filter-markers.js'
            ),
            
            
          },{
            path: '/example/fitbounds/',
            getPage: () => import(
              /* webpackChunkName: "example-fitbounds" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_fitbounds.js'
            ),
            
            
          },{
            path: '/example/flyto-options/',
            getPage: () => import(
              /* webpackChunkName: "example-flyto-options" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_flyto-options.js'
            ),
            
            
          },{
            path: '/example/flyto/',
            getPage: () => import(
              /* webpackChunkName: "example-flyto" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_flyto.js'
            ),
            
            
          },{
            path: '/example/fullscreen/',
            getPage: () => import(
              /* webpackChunkName: "example-fullscreen" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_fullscreen.js'
            ),
            
            
          },{
            path: '/example/game-controls/',
            getPage: () => import(
              /* webpackChunkName: "example-game-controls" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_game-controls.js'
            ),
            
            
          },{
            path: '/example/generate-embed-map/',
            getPage: () => import(
              /* webpackChunkName: "example-generate-embed-map" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_generate-embed-map.js'
            ),
            
            
          },{
            path: '/example/geocoder/',
            getPage: () => import(
              /* webpackChunkName: "example-geocoder" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_geocoder.js'
            ),
            
            
          },{
            path: '/example/geojson-layer-in-stack/',
            getPage: () => import(
              /* webpackChunkName: "example-geojson-layer-in-stack" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_geojson-layer-in-stack.js'
            ),
            
            
          },{
            path: '/example/geojson-line/',
            getPage: () => import(
              /* webpackChunkName: "example-geojson-line" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_geojson-line.js'
            ),
            
            
          },{
            path: '/example/geojson-markers/',
            getPage: () => import(
              /* webpackChunkName: "example-geojson-markers" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_geojson-markers.js'
            ),
            
            
          },{
            path: '/example/geojson-polygon/',
            getPage: () => import(
              /* webpackChunkName: "example-geojson-polygon" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_geojson-polygon.js'
            ),
            
            
          },{
            path: '/example/heatmap-layer/',
            getPage: () => import(
              /* webpackChunkName: "example-heatmap-layer" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_heatmap-layer.js'
            ),
            
            
          },{
            path: '/example/heatmap/',
            getPage: () => import(
              /* webpackChunkName: "example-heatmap" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_heatmap.js'
            ),
            
            
          },{
            path: '/example/hover-styles/',
            getPage: () => import(
              /* webpackChunkName: "example-hover-styles" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_hover-styles.js'
            ),
            
            
          },{
            path: '/example/interactive-false/',
            getPage: () => import(
              /* webpackChunkName: "example-interactive-false" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_interactive-false.js'
            ),
            
            
          },{
            path: '/example/jump-to/',
            getPage: () => import(
              /* webpackChunkName: "example-jump-to" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_jump-to.js'
            ),
            
            
          },{
            path: '/example/language-switch/',
            getPage: () => import(
              /* webpackChunkName: "example-language-switch" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_language-switch.js'
            ),
            
            
          },{
            path: '/example/line-across-180th-meridian/',
            getPage: () => import(
              /* webpackChunkName: "example-line-across-180th-meridian" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_line-across-180th-meridian.js'
            ),
            
            
          },{
            path: '/example/line-gradient/',
            getPage: () => import(
              /* webpackChunkName: "example-line-gradient" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_line-gradient.js'
            ),
            
            
          },{
            path: '/example/live-geojson/',
            getPage: () => import(
              /* webpackChunkName: "example-live-geojson" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_live-geojson.js'
            ),
            
            
          },{
            path: '/example/live-update-feature/',
            getPage: () => import(
              /* webpackChunkName: "example-live-update-feature" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_live-update-feature.js'
            ),
            
            
          },{
            path: '/example/local-geojson-experimental/',
            getPage: () => import(
              /* webpackChunkName: "example-local-geojson-experimental" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_local-geojson-experimental.js'
            ),
            
            
          },{
            path: '/example/local-geojson/',
            getPage: () => import(
              /* webpackChunkName: "example-local-geojson" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_local-geojson.js'
            ),
            
            
          },{
            path: '/example/local-ideographs/',
            getPage: () => import(
              /* webpackChunkName: "example-local-ideographs" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_local-ideographs.js'
            ),
            
            
          },{
            path: '/example/locate-user/',
            getPage: () => import(
              /* webpackChunkName: "example-locate-user" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_locate-user.js'
            ),
            
            
          },{
            path: '/example/map-styles/',
            getPage: () => import(
              /* webpackChunkName: "example-map-styles" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_map-styles.js'
            ),
            
            
          },{
            path: '/example/map-tiles/',
            getPage: () => import(
              /* webpackChunkName: "example-map-tiles" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_map-tiles.js'
            ),
            
            
          },{
            path: '/example/mapbox-gl-draw/',
            getPage: () => import(
              /* webpackChunkName: "example-mapbox-gl-draw" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_mapbox-gl-draw.js'
            ),
            
            
          },{
            path: '/example/mapbox-gl-rtl-text/',
            getPage: () => import(
              /* webpackChunkName: "example-mapbox-gl-rtl-text" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_mapbox-gl-rtl-text.js'
            ),
            
            
          },{
            path: '/example/mapbox-gl-supported/',
            getPage: () => import(
              /* webpackChunkName: "example-mapbox-gl-supported" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_mapbox-gl-supported.js'
            ),
            
            
          },{
            path: '/example/marker-popup/',
            getPage: () => import(
              /* webpackChunkName: "example-marker-popup" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_marker-popup.js'
            ),
            
            
          },{
            path: '/example/measure/',
            getPage: () => import(
              /* webpackChunkName: "example-measure" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_measure.js'
            ),
            
            
          },{
            path: '/example/mouse-position/',
            getPage: () => import(
              /* webpackChunkName: "example-mouse-position" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_mouse-position.js'
            ),
            
            
          },{
            path: '/example/multiple-geometries/',
            getPage: () => import(
              /* webpackChunkName: "example-multiple-geometries" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_multiple-geometries.js'
            ),
            
            
          },{
            path: '/example/navigation/',
            getPage: () => import(
              /* webpackChunkName: "example-navigation" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_navigation.js'
            ),
            
            
          },{
            path: '/example/offset-vanishing-point-with-padding/',
            getPage: () => import(
              /* webpackChunkName: "example-offset-vanishing-point-with-padding" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_offset-vanishing-point-with-padding.js'
            ),
            
            
          },{
            path: '/example/polygon-popup-on-click/',
            getPage: () => import(
              /* webpackChunkName: "example-polygon-popup-on-click" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_polygon-popup-on-click.js'
            ),
            
            
          },{
            path: '/example/popup-on-click/',
            getPage: () => import(
              /* webpackChunkName: "example-popup-on-click" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_popup-on-click.js'
            ),
            
            
          },{
            path: '/example/popup-on-hover/',
            getPage: () => import(
              /* webpackChunkName: "example-popup-on-hover" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_popup-on-hover.js'
            ),
            
            
          },{
            path: '/example/popup/',
            getPage: () => import(
              /* webpackChunkName: "example-popup" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_popup.js'
            ),
            
            
          },{
            path: '/example/queryrenderedfeatures/',
            getPage: () => import(
              /* webpackChunkName: "example-queryrenderedfeatures" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_queryrenderedfeatures.js'
            ),
            
            
          },{
            path: '/example/render-world-copies/',
            getPage: () => import(
              /* webpackChunkName: "example-render-world-copies" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_render-world-copies.js'
            ),
            
            
          },{
            path: '/example/restrict-bounds/',
            getPage: () => import(
              /* webpackChunkName: "example-restrict-bounds" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_restrict-bounds.js'
            ),
            
            
          },{
            path: '/example/satellite-map/',
            getPage: () => import(
              /* webpackChunkName: "example-satellite-map" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_satellite-map.js'
            ),
            
            
          },{
            path: '/example/scroll-fly-to/',
            getPage: () => import(
              /* webpackChunkName: "example-scroll-fly-to" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_scroll-fly-to.js'
            ),
            
            
          },{
            path: '/example/set-perspective/',
            getPage: () => import(
              /* webpackChunkName: "example-set-perspective" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_set-perspective.js'
            ),
            
            
          },{
            path: '/example/set-popup/',
            getPage: () => import(
              /* webpackChunkName: "example-set-popup" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_set-popup.js'
            ),
            
            
          },{
            path: '/example/simple-map/',
            getPage: () => import(
              /* webpackChunkName: "example-simple-map" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_simple-map.js'
            ),
            
            
          },{
            path: '/example/third-party/',
            getPage: () => import(
              /* webpackChunkName: "example-third-party" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_third-party.js'
            ),
            
            
          },{
            path: '/example/timeline-animation/',
            getPage: () => import(
              /* webpackChunkName: "example-timeline-animation" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_timeline-animation.js'
            ),
            
            
          },{
            path: '/example/toggle-interaction-handlers/',
            getPage: () => import(
              /* webpackChunkName: "example-toggle-interaction-handlers" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_toggle-interaction-handlers.js'
            ),
            
            
          },{
            path: '/example/using-featuresin/',
            getPage: () => import(
              /* webpackChunkName: "example-using-featuresin" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_using-featuresin.js'
            ),
            
            
          },{
            path: '/example/variable-label-placement/',
            getPage: () => import(
              /* webpackChunkName: "example-variable-label-placement" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_variable-label-placement.js'
            ),
            
            
          },{
            path: '/example/vector-source/',
            getPage: () => import(
              /* webpackChunkName: "example-vector-source" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_vector-source.js'
            ),
            
            
          },{
            path: '/example/visualize-population-density/',
            getPage: () => import(
              /* webpackChunkName: "example-visualize-population-density" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_visualize-population-density.js'
            ),
            
            
          },{
            path: '/example/vrp/',
            getPage: () => import(
              /* webpackChunkName: "example-vrp" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_vrp.js'
            ),
            
            
          },{
            path: '/example/wms/',
            getPage: () => import(
              /* webpackChunkName: "example-wms" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_wms.js'
            ),
            
            
          },{
            path: '/example/zoomto-linestring/',
            getPage: () => import(
              /* webpackChunkName: "example-zoomto-linestring" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_example_zoomto-linestring.js'
            ),
            
            
          },{
            path: '/examples/',
            getPage: () => import(
              /* webpackChunkName: "examples" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_examples.js'
            ),
            
            
          },{
            path: '/integration/',
            getPage: () => import(
              /* webpackChunkName: "integration" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration.js'
            ),
            
            
          },{
            path: '/integration/auto-complete-geocoding-v2/',
            getPage: () => import(
              /* webpackChunkName: "integration-auto-complete-geocoding-v2" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_auto-complete-geocoding-v2.js'
            ),
            
            
          },{
            path: '/integration/auto-complete-geocoding/',
            getPage: () => import(
              /* webpackChunkName: "integration-auto-complete-geocoding" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_auto-complete-geocoding.js'
            ),
            
            
          },{
            path: '/integration/directions-v2/',
            getPage: () => import(
              /* webpackChunkName: "integration-directions-v2" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_directions-v2.js'
            ),
            
            
          },{
            path: '/integration/directions/',
            getPage: () => import(
              /* webpackChunkName: "integration-directions" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_directions.js'
            ),
            
            
          },{
            path: '/integration/distance-matrix/',
            getPage: () => import(
              /* webpackChunkName: "integration-distance-matrix" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_distance-matrix.js'
            ),
            
            
          },{
            path: '/integration/reverse-geocoding-v2/',
            getPage: () => import(
              /* webpackChunkName: "integration-reverse-geocoding-v2" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_reverse-geocoding-v2.js'
            ),
            
            
          },{
            path: '/integration/reverse-geocoding/',
            getPage: () => import(
              /* webpackChunkName: "integration-reverse-geocoding" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_reverse-geocoding.js'
            ),
            
            
          },{
            path: '/integration/search-geocoding-v2/',
            getPage: () => import(
              /* webpackChunkName: "integration-search-geocoding-v2" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_search-geocoding-v2.js'
            ),
            
            
          },{
            path: '/integration/search-geocoding/',
            getPage: () => import(
              /* webpackChunkName: "integration-search-geocoding" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_search-geocoding.js'
            ),
            
            
          },{
            path: '/integration/static-map/',
            getPage: () => import(
              /* webpackChunkName: "integration-static-map" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_static-map.js'
            ),
            
            
          },{
            path: '/integration/vehicle-routing-problem/',
            getPage: () => import(
              /* webpackChunkName: "integration-vehicle-routing-problem" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_integration_vehicle-routing-problem.js'
            ),
            
            
          },{
            path: '/overview/',
            getPage: () => import(
              /* webpackChunkName: "overview" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_overview.js'
            ),
            
            
          },{
            path: '/plugins/',
            getPage: () => import(
              /* webpackChunkName: "plugins" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_plugins.js'
            ),
            
            
          },{
            path: '/style/',
            getPage: () => import(
              /* webpackChunkName: "style" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style.js'
            ),
            
            
          },{
            path: '/style/expressions/',
            getPage: () => import(
              /* webpackChunkName: "style-expressions" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_expressions.js'
            ),
            
            
          },{
            path: '/style/glyphs/',
            getPage: () => import(
              /* webpackChunkName: "style-glyphs" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_glyphs.js'
            ),
            
            
          },{
            path: '/style/layers/',
            getPage: () => import(
              /* webpackChunkName: "style-layers" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_layers.js'
            ),
            
            
          },{
            path: '/style/light/',
            getPage: () => import(
              /* webpackChunkName: "style-light" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_light.js'
            ),
            
            
          },{
            path: '/style/root/',
            getPage: () => import(
              /* webpackChunkName: "style-root" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_root.js'
            ),
            
            
          },{
            path: '/style/sources/',
            getPage: () => import(
              /* webpackChunkName: "style-sources" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_sources.js'
            ),
            
            
          },{
            path: '/style/sprite/',
            getPage: () => import(
              /* webpackChunkName: "style-sprite" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_sprite.js'
            ),
            
            
          },{
            path: '/style/terrain/',
            getPage: () => import(
              /* webpackChunkName: "style-terrain" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_terrain.js'
            ),
            
            
          },{
            path: '/style/transition/',
            getPage: () => import(
              /* webpackChunkName: "style-transition" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_transition.js'
            ),
            
            
          },{
            path: '/style/types/',
            getPage: () => import(
              /* webpackChunkName: "style-types" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_style_types.js'
            ),
            
            
          }],
            notFoundRoute: {
            path: '/404/',
            getPage: () => import(
              /* webpackChunkName: "not-found" */
              '/home/duy/advn/track-asia/trackasia-gl-js-docs/_batfish_tmp/_404.js'
            ),
            
            is404: true,
          }
          };